import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LocaleContext from '../context/localeContext';

const LocalizedLink = ({ to, className, children }) => {
  return (
    <LocaleContext.Consumer>
      {(context) => (
        <Link className={`${className}`} to={context.localize(to)}>
          {children}
        </Link>
      )}
    </LocaleContext.Consumer>
  );
};

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default LocalizedLink;
