import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ colorScheme, overlaySide, overlayLength, children }) => {
  function mainColorClass() {
    switch (colorScheme) {
      case 'white':
        return 'bg-white text-gray-900';
      case 'gray':
        return 'bg-gray-100 text-gray-900';
      case 'black':
        return 'bg-black text-white';
      default:
        return 'bg-gray-100 text-gray-900';
    }
  }
  function beforeColorClass() {
    switch (colorScheme) {
      case 'white':
        return 'before:bg-white';
      case 'gray':
        return 'before:bg-gray-100';
      case 'black':
        return 'before:bg-black';
      default:
        return 'before:bg-gray-100';
    }
  }
  function beforeSideClass() {
    switch (overlaySide) {
      case 'left':
        return 'before:left-0';
      case 'right':
        return 'before:right-0';
      default:
        return 'before:right-0';
    }
  }
  function beforeLengthClass() {
    switch (overlayLength) {
      case 'none':
        return 'before:w-0 before:md:w-0';
      case 'small':
        return 'before:w-1/2 before:md:w-1/5';
      case 'large':
        return 'before:w-1/2 before:md:w-4/5';
      default:
        return 'before:w-1/2 before:md:w-1/5';
    }
  }

  return (
    <section
      className={`${mainColorClass()} relative pb-8 ${beforeColorClass()} before:h-8 before:block ${beforeLengthClass()} before:absolute ${beforeSideClass()}  before:-top-8`}
    >
      {children}
    </section>
  );
};

Section.propTypes = {
  colorScheme: PropTypes.string,
  overlaySide: PropTypes.string,
  overlayLength: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
