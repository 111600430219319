import * as React from 'react';
import { useContext, useState } from 'react';

import LocaleContext from '../context/localeContext';
import LocalizedLink from '../components/LocalizedLink';
import { SearchIcon } from '../components/Icon';

export default function Search() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const { locale } = useContext(LocaleContext);

  const handleSearchInput = (e) => {
    let searchInput = e.target.value;
    if (searchInput.length > 0) {
      const results = getSearchResults(searchInput);
      setQuery(searchInput);
      setResults(results);
    } else {
      setQuery(searchInput);
      setResults([]);
    }
  };

  const getSearchResults = (query) => {
    var index = window.__FLEXSEARCH__.en.index;
    var store = window.__FLEXSEARCH__.en.store;
    if (!query || !index) {
      return [];
    } else {
      var results = [];
      Object.keys(index).forEach((idx) => {
        results.push(...index[idx].values.search(query));
      });

      results = Array.from(new Set(results));
      var nodes = store
        .filter((node) => node.node.locale === locale)
        .filter((node) => (results.includes(node.id) ? node : null))
        .map((node) => node.node);

      return nodes;
    }
  };

  return (
    <div className="h-full">
      <h1 className="mb-10">
        <span className="bg-theme-underline">Search</span>
      </h1>
      <div className="relative text-black mb-6">
        <input
          id="mainSearch"
          type="text"
          className="w-full rounded-md p-3"
          placeholder="Search Keyword"
          value={query}
          onChange={handleSearchInput}
        />
        <SearchIcon className="absolute h-6 w-6 right-2 top-1/2 -translate-y-1/2" />
      </div>
      <div className="results overflow-scroll h-4/5">
        {query && (
          <p className="font-medium mb-6">
            {results.length} results for '{query}'
          </p>
        )}
        {results.map((page) => (
          <LocalizedLink
            to={page.slug}
            className="block mb-4 mx-6 md:mx-10"
            key={page.id}
          >
            <span className="bg-none hover:bg-theme-underline">
              {page.title}
            </span>
          </LocalizedLink>
        ))}
      </div>
    </div>
  );
}
