import * as React from 'react';

export const BurgerIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    strokeWidth="4"
    stroke="currentColor"
    fillRule="evenodd"
  >
    <path d="M0,2 L48,2"></path>
    <path d="M0,22 L24,22"></path>
    <path d="M24,26 L48,26"></path>
    <path d="M0,44 L48,44"></path>
  </svg>
);

export const MultiplyIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    strokeWidth="4"
    stroke="currentColor"
    fillRule="evenodd"
  >
    <path d="M2,2 L46,46"></path>
    <path d="M2,46 L46,2"></path>
  </svg>
);

export const MultiplyCircleIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
  >
    <path d="M14,14 L34,34"></path>
    <path d="M14,34 L34,14"></path>
    <circle cx="24" cy="24" r="23"></circle>
  </svg>
);

export const SearchIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 19"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g transform="translate(1.000000, 1.000000)">
      <circle id="Oval" cx="5.87931705" cy="5.87931705" r="5.87931705"></circle>
      <line
        x1="12.8820213"
        y1="17.2915091"
        x2="12.8820213"
        y2="9.45241967"
        id="Line"
        transform="translate(12.882021, 13.371964) rotate(135.000000) translate(-12.882021, -13.371964) "
      ></line>
    </g>
  </svg>
);

export const ArrowRightCircleIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    strokeWidth="4"
    stroke="currentColor"
    fill="None"
  >
    <circle strokeWidth="2" cx="24" cy="24" r="23"></circle>
    <g
      transform="translate(14.149555, 15.575626)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <polyline
        transform="translate(13.797451, 7.650403) rotate(45.000000) translate(-13.797451, -7.650403)"
        points="8.32913534 2.29941536 19.2657674 2.18208696 19.148439 13.1187191"
      ></polyline>
      <line x1="20.623213" y1="7.65040301" x2="0" y2="7.65040301"></line>
    </g>
  </svg>
);

export const ChevronDownCircleIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    strokeWidth="2"
    stroke="currentColor"
    fill="None"
  >
    <circle cx="24" cy="24" r="23"></circle>
    <path
      d="M18.5150507,14.8823791 L29.4516828,14.7650507 L29.3343544,25.7016828"
      transform="translate(23.983367, 20.233367) rotate(-225.000000) translate(-23.983367, -20.233367) "
    ></path>
  </svg>
);

export const ArrowLeftIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="None"
  >
    <g transform="translate(12.500000, 10.000000) scale(-1, 1) translate(-12.500000, -10.000000) translate(2.000000, 2.000000)">
      <path d="M20.2606504,7.93627927 L0,7.93627927"></path>
      <path d="M14.632692,1.45046958 L21.0000001,7.93627908 L14.6326919,14.4220885"></path>
    </g>
  </svg>
);

export const ArrowRightIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="None"
  >
    <g transform="translate(1.000000, 2.000000)">
      <line x1="20.3067376" y1="7.82324895" x2="0" y2="7.82324895"></line>
      <polyline
        transform="translate(13.504031, 7.823249) rotate(45.000000) translate(-13.504031, -7.823249) "
        points="7.97215838 2.29137657 18.9461962 2.38108347 19.0359031 13.3551213"
      ></polyline>
    </g>
  </svg>
);

export const CogIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="26"
    strokeWidth="26px"
  >
    <path d="M430,930H570V842a19.27,19.27,0,0,1,15-19c29-8,58-19,84-34,8-4,18-3,24,3l62,62,99-99-60-60a19.82,19.82,0,0,1-3-25c16-27,29-55,37-85a19.27,19.27,0,0,1,19-15h83V430H850c-9,0-18-6-20-15-8-31-19-62-36-90-4-8-3-18,3-24l56-56-99-99-54,55a19.82,19.82,0,0,1-25,3,331.07,331.07,0,0,0-91-38,19.27,19.27,0,0,1-15-19V70H430v76a19.27,19.27,0,0,1-15,19,331.07,331.07,0,0,0-91,38,19.82,19.82,0,0,1-25-3l-54-54-99,99,56,56c6,6,8,16,3,24a317.8,317.8,0,0,0-35,90c-2,9-11,16-20,15H70V570h83a19.27,19.27,0,0,1,19,15,322.4,322.4,0,0,0,37,85c5,8,4,18-3,24l-60,60,99,99,61-62c6-6,16-8,24-3,26,15,55,26,84,34,9,2,15,10,14,20v88Zm70-248A182,182,0,1,1,682,500h0C682,600,600,682,500,682Z" />
  </svg>
);

export const EyeIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="26"
    strokeWidth="26px"
  >
    <circle cx="500" cy="498.56" r="163.19" />
    <path d="M663.19,498.56A163.19,163.19,0,0,1,500,661.75c-195.82,0-324.82-163.19-324.82-163.19S308.84,335.38,500,335.38A163.19,163.19,0,0,1,663.19,498.56Z" />
    <path d="M663.19,498.56A163.19,163.19,0,0,1,500,661.75c-195.82,0-324.82-163.19-324.82-163.19S308.84,335.38,500,335.38A163.19,163.19,0,0,1,663.19,498.56Z" />
    <path d="M336.81,498.56A163.19,163.19,0,0,1,500,335.38c195.82,0,324.82,163.18,324.82,163.18S691.16,661.75,500,661.75A163.19,163.19,0,0,1,336.81,498.56Z" />
    <circle cx="500" cy="498.56" r="53.04" />
    <line x1="500" y1="216.73" x2="500" y2="119.32" />
    <line x1="300.71" y1="299.27" x2="231.83" y2="230.4" />
    <line x1="300.71" y1="697.85" x2="231.83" y2="766.73" />
    <line x1="500" y1="780.4" x2="500" y2="877.81" />
    <line x1="699.29" y1="697.85" x2="768.17" y2="766.73" />
    <line x1="699.29" y1="299.27" x2="768.17" y2="230.4" />
  </svg>
);

export const BullsIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="26"
    strokeWidth="26px"
  >
    <path d="M492.29,341A166.67,166.67,0,1,0,659,507.71" />
    <path d="M616.82,110.48l-.79-.25C395.74,41.14,161.15,163.71,92.06,384s53.48,454.88,273.77,524,454.88-53.48,524-273.77a418,418,0,0,0,0-250.23" />
    <path d="M511.13,217.1c-6.23-.55-12.45-1.06-18.84-1.06-161.08,0-291.67,130.59-291.67,291.67S331.21,799.38,492.29,799.38,784,668.79,784,507.71a184.68,184.68,0,0,0-1.39-22.54" />
    <line x1="490.87" y1="509.1" x2="671.59" y2="328.39" />
    <polygon points="920.87 173.91 766.23 328.55 671.59 328.39 671.42 233.75 826.06 79.1 826.23 173.74 920.87 173.91" />
  </svg>
);

export const ScaleIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="26"
    strokeWidth="26px"
  >
    <circle cx="500" cy="210.85" r="39.12" />
    <line x1="460.88" y1="210.85" x2="237.08" y2="210.85" />
    <line x1="762.92" y1="210.85" x2="539.12" y2="210.85" />
    <path d="M640.29,511.63a122.63,122.63,0,0,0,245.26,0" />
    <polygon points="640.29 511.63 762.92 211.09 885.55 511.63 640.29 511.63" />
    <path d="M114.45,511.63a122.63,122.63,0,0,0,245.26,0" />
    <polygon points="114.45 511.63 237.08 211.09 359.71 511.63 114.45 511.63" />
    <line x1="500" y1="832.93" x2="500" y2="249.97" />
    <line x1="197.09" y1="832.93" x2="802.91" y2="832.93" />
  </svg>
);

export const FlagIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="26"
    strokeWidth="26px"
  >
    <line x1="389.44" y1="843.43" x2="122.94" y2="156.57" />
    <line x1="263.28" y1="506.66" x2="547.72" y2="481.63" />
    <path d="M148.37,222.12l194.41-17.84A133.31,133.31,0,0,1,480.4,291.9l67.88,188.69a133.32,133.32,0,0,0,143.49,87L871,543.05,725.28,412.42l17-138.18L482.82,298.63" />
  </svg>
);

export const QuotationIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61 61"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="2"
    fillRule="nonzero"
  >
    <g transform="translate(0, 1.4)">
      <path d="M23.4,51.6 L23.4,29.4 L11.6,29.4 C11.6,27.1333333 11.7666667,24.8333333 12.1,22.5 C12.4333333,20.1666667 13.0666667,18.0666667 14,16.2 C14.9333333,14.3333333 16.1333333,12.8333333 17.6,11.7 C19.0666667,10.5666667 20.9333333,10 23.2,10 L23.2,-2.27373675e-13 C19.4666667,-2.27373675e-13 16.2,0.8 13.4,2.4 C10.6,4 8.3,6.13333333 6.5,8.8 C4.7,11.4666667 3.36666667,14.5666667 2.5,18.1 C1.63333333,21.6333333 1.2,25.4 1.2,29.4 L1.2,51.6 L23.4,51.6 Z M60,51.6 L60,29.4 L48.2,29.4 C48.2,27.1333333 48.3666667,24.8333333 48.7,22.5 C49.0333333,20.1666667 49.6666667,18.0666667 50.6,16.2 C51.5333333,14.3333333 52.7333333,12.8333333 54.2,11.7 C55.6666667,10.5666667 57.5333333,10 59.8,10 L59.8,-2.27373675e-13 C56.0666667,-2.27373675e-13 52.8,0.8 50,2.4 C47.2,4 44.9,6.13333333 43.1,8.8 C41.3,11.4666667 39.9666667,14.5666667 39.1,18.1 C38.2333333,21.6333333 37.8,25.4 37.8,29.4 L37.8,51.6 L60,51.6 Z"></path>
    </g>
  </svg>
);

export const ContrastIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
    strokeWidth="1"
    fillRule="nonzero"
  >
    <g transform="translate(1, 1)">
      <circle
        stroke="currentColor"
        fillOpacity="0"
        cx="18"
        cy="18"
        r="8"
      ></circle>
      <path
        fill="currentColor"
        d="M26,26 C21.5816622,26 18,22.4183378 18,18 C18,13.5819091 21.5816622,10 26,10 L26,26 Z"
        transform="translate(22.000000, 18.000000) rotate(-180.000000) translate(-22.000000, -18.000000) "
      ></path>
    </g>
  </svg>
);

export const TextSizeIcon = ({ className }) => (
  <svg
    className={`icon ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
    fill="currentColor"
    stroke="transparent"
    strokeWidth="1"
    fillRule="nonzero"
  >
    <g transform="translate(11.442750, 13.778625)">
      <polygon
        id="Path"
        points="4.74525 10.1745 4.74525 1.14 8.13675 1.14 8.13675 -9.49240686e-15 -8.22675261e-14 -9.49240686e-15 -8.22675261e-14 1.14 3.3915 1.14 3.3915 10.1745"
      ></polygon>
      <polygon
        id="Path"
        strokeWidth="0.5"
        points="12.7218771 10.1745 12.7218771 3.84781933 15.0968771 3.84781933 15.0968771 3.0495 9.3988729 3.0495 9.3988729 3.84781933 11.7738729 3.84781933 11.7738729 10.1745"
      ></polygon>
    </g>
  </svg>
);
