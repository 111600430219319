import React from 'react';

import { locales, defaultLocale } from '../config/i18n.config';

const defaultState = {
  locales: locales,
  locale: defaultLocale,
};

const LocaleContext = React.createContext(defaultState);

class LocaleProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale,
    };
  }

  localize = (link, locale) => {
    let targetLocale = locale ? locale : this.state.locale;

    if (targetLocale === defaultLocale) {
      return link;
    }
    if (link.startsWith(`/${targetLocale}/`)) {
      return link;
    }
    return `/${targetLocale}${link}`;
  };

  changeLocale = (newLocale) => {
    if (newLocale === this.state.locale) {
      return;
    }

    let currentPath =
      this.state.locale === defaultLocale
        ? window.location.pathname
        : window.location.pathname.slice(3);
    let newPath = this.localize(currentPath, newLocale);

    window.location.pathname = newPath;
  };

  render() {
    const { children } = this.props;
    const { locale } = this.state;
    return (
      <LocaleContext.Provider
        value={{
          locale,
          locales: locales,
          localize: this.localize,
          changeLocale: this.changeLocale,
        }}
      >
        {children}
      </LocaleContext.Provider>
    );
  }
}

export default LocaleContext;

export { LocaleProvider };
