import * as React from 'react';

import ContrastContext from '../context/contrastContext';
import TextSizeContext from '../context/textSizeContext';
// import LocaleContext from '../context/localeContext'
import logoWhite from '../img/logo-white.svg';
import LocalizedLink from '../components/LocalizedLink';
// import Select from "../components/Select";
import { ContrastIcon, TextSizeIcon } from '../components/Icon';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer text-white mt-auto pt-6">
        <div className="container flex items-center justify-between py-5 md:py-7">
          <div className="md:max-w-xs">
            <LocalizedLink to="/" className="block w-28 md:w-48 pb-6">
              <img src={logoWhite} alt="GDI" />
            </LocalizedLink>
            <nav className="grid grid-cols-2 gap-x-8 pb-6 md:hidden text-base">
              <LocalizedLink className="inline-block py-1" to="/contact">
                Contact
              </LocalizedLink>
              <LocalizedLink className="inline-block py-1" to="/careers">
                Jobs
              </LocalizedLink>
              <LocalizedLink className="inline-block py-1" to="/events">
                Events
              </LocalizedLink>
              <LocalizedLink className="inline-block py-1" to="/press">
                Press
              </LocalizedLink>
              <LocalizedLink className="inline-block py-1" to="/privacy">
                Privacy
              </LocalizedLink>
              <LocalizedLink className="inline-block py-1" to="https://www.linkedin.com/company/the-global-disinformation-index">
                LinkedIn
              </LocalizedLink>
            </nav>
            <hr className="border-t border-t-gray-400 pb-6 md:hidden" />
            <div className="inline-flex gap-x-12 md:gap-x-6 mb-4">
              <div>
                <span className="block mb-2">Accessibility:</span>
                <ContrastContext.Consumer>
                  {(contrastContext) => (
                    <>
                      <TextSizeContext.Consumer>
                        {(textContext) => (
                          <div
                            className={`inline-block mr-4 rounded-full border border-white cursor-pointer bg-transparent hover:bg-white ${
                              textContext.isLgTextEnabled
                                ? `${
                                    contrastContext.isHighContrastEnabled
                                      ? 'text-primaryHC'
                                      : 'text-primary'
                                  }`
                                : 'text-white hover:text-black'
                            }`}
                            title={`${
                              textContext.isLgTextEnabled ? 'Disable' : 'Enable'
                            } large font`}
                            tabIndex={0}
                            role="button"
                            onKeyPress={textContext.toggleLgText}
                            onClick={textContext.toggleLgText}
                          >
                            <TextSizeIcon className="w-12 h-12" />
                          </div>
                        )}
                      </TextSizeContext.Consumer>
                      <div
                        className={`inline-block rounded-full border border-white cursor-pointer bg-transparent hover:bg-white ${
                          contrastContext.isHighContrastEnabled
                            ? 'text-primaryHC'
                            : 'text-white hover:text-black'
                        }`}
                        title={`${
                          contrastContext.isHighContrastEnabled
                            ? 'Disable'
                            : 'Enable'
                        } high contrast`}
                        tabIndex={0}
                        role="button"
                        onKeyPress={contrastContext.toggleHighContrast}
                        onClick={contrastContext.toggleHighContrast}
                      >
                        <ContrastIcon className="w-12 h-12" />
                      </div>
                    </>
                  )}
                </ContrastContext.Consumer>
              </div>
              {/* <div>
                <LocaleContext.Consumer>
                  {context => (
                    <Select label="Language" options={context.locales} value={context.locale} handleChange={context.changeLocale} />
                  )}
                </LocaleContext.Consumer>
              </div> */}
            </div>
            <p className="text-xs">
              © Copyright - Disinformation Index Ltd., All Rights Reserved GDI
              is a not-for-profit company; any surplus income generated will be
              100% reinvested back into the organisation to further its mission.
            </p>
          </div>
          <nav className="hidden md:grid grid-cols-2 gap-x-16 text-base">
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="/contact"
              >
                Contact
              </LocalizedLink>
            </div>
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="/careers"
              >
                Jobs
              </LocalizedLink>
            </div>
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="/events"
              >
                Events
              </LocalizedLink>
            </div>
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="/press"
              >
                Press
              </LocalizedLink>
            </div>
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="/privacy"
              >
                Privacy
              </LocalizedLink>
            </div>
            <div className="my-1 pb-2">
              <LocalizedLink
                className="inline hover:bg-theme-underline"
                to="https://www.linkedin.com/company/the-global-disinformation-index"
              >
                LinkedIn
              </LocalizedLink>
            </div>
          </nav>
        </div>
      </footer>
    );
  }
};

export default Footer;
