import * as React from 'react';
import PropTypes from 'prop-types';

import { MarkdownContent } from '../components/Content';

const Hero = ({ image, content, autoHeaderText }) => {
  return (
    <div className="hero overflow-hidden">
      <div
        className="grid bg-center bg-cover"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp
              ? image.childImageSharp.gatsbyImageData.images.fallback.src
              : image
          })`,
        }}
      >
        <div
          className="grid relative bg-gradient-to-t from-black to-transparent"
          style={{ gridArea: '1/1' }}
        >
          <svg
            className="absolute top-36 left-1/2 -translate-x-1/2"
            width="1775px"
            height="409px"
            viewBox="0 0 1775 409"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#FFFFFF"
            fillOpacity="0.3"
            opacity="0.598996185"
          >
            <rect x="0" y="23" width="199" height="23"></rect>
            <rect x="495" y="335" width="199" height="23"></rect>
            <rect x="1254" y="0" width="521" height="23"></rect>
            <rect x="341" y="98" width="199" height="23"></rect>
            <rect x="32" y="265" width="115" height="23"></rect>
            <rect x="0" y="23" width="90" height="23"></rect>
            <rect x="341" y="98" width="90" height="23"></rect>
            <rect x="495" y="335" width="90" height="23"></rect>
            <rect x="1254" y="0" width="235.628141" height="23"></rect>
            <rect x="32" y="265" width="52.0100503" height="23"></rect>
            <rect x="890" y="386" width="209" height="23"></rect>
          </svg>
          <div className="container z-10 flex mt-24 md:mt-48">
            <div className="self-end pt-24 pb-16 md:pt-36 md:pb-32">
              {content && (
                <span
                  className={`block pb-10 max-w-3xl ${
                    autoHeaderText ? 'text-3xl' : 'text-2xl'
                  }`}
                >
                  <MarkdownContent content={content} />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  autoHeaderText: PropTypes.bool,
};

Hero.defaultProps = {
  image: '',
  content: '',
  autoHeaderText: true,
};

export default Hero;
