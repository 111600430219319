import * as React from 'react';
import { createContext } from 'react';

import useLocalStorage from '../hooks/UseLocalStorage';

const TextSizeContext = createContext();

export function TextSizeProvider({ children }) {
  const [isLgTextEnabled, setIsLgTextEnabled] = useLocalStorage(
    'isLgTextEnabled',
    false
  );

  const toggleLgText = () => {
    setIsLgTextEnabled(!isLgTextEnabled);
  };

  return (
    <TextSizeContext.Provider value={{ isLgTextEnabled, toggleLgText }}>
      {children}
    </TextSizeContext.Provider>
  );
}

export default TextSizeContext;
