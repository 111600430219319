import * as React from 'react';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LocalizedLink from '../components/LocalizedLink';
import ContrastContext from '../context/contrastContext';

function LinkButton({
  text,
  to,
  className,
  theme = 'primary',
  isDisabled = false,
  isExternal = false,
  localize = true,
}) {
  const { isHighContrastEnabled } = useContext(ContrastContext) || {};

  if (to === null) {
    isDisabled = true;
  }

  className = `${
    className == null ? '' : className
  } button inline-block px-12 py-3 rounded-full no-underline hover:no-underline focus:no-underline`;

  if (isDisabled) {
    if (theme === 'secondary-light') {
      className += isHighContrastEnabled ? ' bg-gray-500' : ' bg-gray-300';
    } else if (theme === 'secondary-dark') {
      className += ' bg-gray-500';
    } else {
      className += isHighContrastEnabled
        ? ' bg-gray-500'
        : ' bg-primaryDisabled';
    }
  } else {
    if (theme === 'secondary-dark') {
      className +=
        ' bg-transparent border text-gray-900 border-gray-900 hover:bg-gray-900 hover:text-white focus:bg-gray-700 focus:text-white';
    } else if (theme === 'secondary-light') {
      className +=
        ' bg-transparent border border-white text-white hover:bg-white hover:text-black focus:border-gray-300 focus:bg-gray-300 focus:text-black';
    } else {
      if (isHighContrastEnabled) {
        className +=
          ' bg-primaryHC text-white hover:bg-primaryHoverHC focus:bg-primaryPressedHC hover:text-white focus:text-white';
      } else {
        className +=
          ' bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed hover:text-white focus:text-white';
      }
    }
  }

  if (isDisabled) {
    return <span className={className}>{text}</span>;
  }
  if (isExternal) {
    return (
      <a
        className={className}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
  }
  if (localize) {
    return (
      <LocalizedLink className={className} to={to}>
        {text}
      </LocalizedLink>
    );
  }
  return (
    <Link className={className} to={to}>
      {text}
    </Link>
  );
}

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool,
};

LinkButton.defaultProps = {
  theme: 'primary',
  isDisabled: false,
  isExternal: false,
};

export default LinkButton;
