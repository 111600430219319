import * as React from 'react';
import { createContext } from 'react';

import useLocalStorage from '../hooks/UseLocalStorage';

const ContrastContext = createContext();

export function ContrastProvider({ children }) {
  const [isHighContrastEnabled, setIsHighContrastEnabled] = useLocalStorage(
    'isHighContrastEnabled',
    false
  );

  const toggleHighContrast = () => {
    setIsHighContrastEnabled(!isHighContrastEnabled);
  };

  return (
    <ContrastContext.Provider
      value={{
        isHighContrastEnabled,
        toggleHighContrast,
      }}
    >
      {children}
    </ContrastContext.Provider>
  );
}

export default ContrastContext;
