import React from 'react';
import { Helmet } from 'react-helmet';

import useSiteMetadata from '../components/SiteMetadata';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import LocaleContext from '../context/localeContext';
import { ContrastProvider } from '../context/contrastContext';
import TextSizeContext, { TextSizeProvider } from '../context/textSizeContext';
import appleTouchIcon from '../img/apple-touch-icon.png';
import favicon16 from '../img/favicon-16x16.png';
import favicon32 from '../img/favicon-32x32.png';
import safariPinnedTab from '../img/safari-pinned-tab.svg';

import '../styles/global.css';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <ContrastProvider>
      <TextSizeProvider>
        <TextSizeContext.Consumer>
          {(textContext) => (
            <div
              className={`min-h-screen flex flex-col bg-black ${
                textContext.isLgTextEnabled ? 'embiggen-text' : ''
              }`}
            >
              <LocaleContext.Consumer>
                {(context) => (
                  <Helmet>
                    <html lang={context.locale} />
                  </Helmet>
                )}
              </LocaleContext.Consumer>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />

                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={appleTouchIcon}
                />
                <link
                  rel="icon"
                  type="image/png"
                  href={favicon32}
                  sizes="32x32"
                />
                <link
                  rel="icon"
                  type="image/png"
                  href={favicon16}
                  sizes="16x16"
                />

                <link rel="mask-icon" href={safariPinnedTab} color="#ff4400" />
                <meta name="theme-color" content="#000" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
              </Helmet>
              <Navbar />
              <div className="text-white">{children}</div>
              <Footer />
            </div>
          )}
        </TextSizeContext.Consumer>
      </TextSizeProvider>
    </ContrastProvider>
  );
};

export default TemplateWrapper;
