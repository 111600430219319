import * as React from 'react';

import LocalizedLink from '../components/LocalizedLink';
import {
  BurgerIcon,
  MultiplyIcon,
  SearchIcon,
  ArrowRightCircleIcon,
} from '../components/Icon';
import Search from '../components/Search';
// import LanguageBanner from '../components/LanguageBanner';
import logoWhite from '../img/logo-white.svg';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      document.body.classList.remove('overflow-hidden');
    }
    this.state = {
      isSmNavVisible: false,
      isSearchVisible: false,
      results: [],
      query: '',
    };
  }

  disableScroll = () => {
    if (this.state.isSmNavVisible || this.state.isSearchVisible) {
      document.body.classList.add('overflow-hidden');
      if (this.state.isSearchVisible) {
        document.getElementById('mainSearch').focus();
      }
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  toggleSmNav = () => {
    this.setState(
      {
        isSmNavVisible: !this.state.isSmNavVisible,
        isSearchVisible: false,
      },
      this.disableScroll
    );
  };

  toggleSearch = () => {
    this.setState(
      {
        isSmNavVisible: false,
        isSearchVisible: !this.state.isSearchVisible,
      },
      this.disableScroll
    );
  };

  render() {
    return (
      <nav
        id="nav-bar"
        role="navigation"
        aria-label="main-navigation"
        className={`absolute left-0 right-0 z-50 text-white text-base pb-8 ${
          this.state.isSmNavVisible || this.state.isSearchVisible
            ? '!bg-black fixed inset-0'
            : ''
        }`}
      >
        {/* <LanguageBanner /> */}
        <div className="container flex items-center justify-between py-5 md:py-7">
          <LocalizedLink to="/" className="block w-28 md:w-48" title="Logo">
            <img src={logoWhite} alt="GDI" />
          </LocalizedLink>
          <div>
            <div
              className={`navbar-search inline-block h-6 w-6 mr-6 md:hidden cursor-pointer`}
              data-target="search"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleSearch()}
              onClick={() => this.toggleSearch()}
            >
              {this.state.isSearchVisible ? <MultiplyIcon /> : <SearchIcon />}
            </div>
            <div
              className={`navbar-burger inline-block h-6 w-6 md:hidden cursor-pointer`}
              data-target="smNavMenu"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleSmNav()}
              onClick={() => this.toggleSmNav()}
            >
              {this.state.isSmNavVisible ? <MultiplyIcon /> : <BurgerIcon />}
            </div>
          </div>
          <div id="navMenu" className="navbar-menu hidden md:block">
            <LocalizedLink
              className="inline-block py-1 pr-3 lg:pr-6"
              to="/about"
            >
              <span className="bg-none hover:bg-theme-underline">
                Who We Are
              </span>
            </LocalizedLink>
            <LocalizedLink
              className="inline-block py-1 pr-3 lg:pr-6"
              to="/mission"
            >
              <span className="bg-none hover:bg-theme-underline">
                Our Mission
              </span>
            </LocalizedLink>
            <LocalizedLink
              className="inline-block py-1 pr-3 lg:pr-6"
              to="/product"
            >
              <span className="bg-none hover:bg-theme-underline">
                What We Do
              </span>
            </LocalizedLink>
            <LocalizedLink
              className="inline-block py-1 pr-3 lg:pr-6"
              to="/research"
            >
              <span className="bg-none hover:bg-theme-underline">
                Our Research
              </span>
            </LocalizedLink>
            <LocalizedLink
              className="inline-block py-1 pr-3 lg:pr-6"
              to="/blog"
            >
              <span className="bg-none hover:bg-theme-underline">Latest</span>
            </LocalizedLink>
            <span className="inline-block py-1">
              <span
                className="bg-none hover:bg-theme-underline"
                data-target="search"
                role="menuitem"
                tabIndex={0}
                onKeyPress={() => this.toggleSearch()}
                onClick={() => this.toggleSearch()}
              >
                <SearchIcon className="inline-block text-white py-1 h-6 w-6 cursor-pointer" />
              </span>
            </span>
          </div>
        </div>
        <div
          id="smNavMenu"
          className={`navbar-menu ${
            this.state.isSmNavVisible ? 'block' : 'hidden'
          } md:hidden`}
        >
          <div className="container">
            <LocalizedLink
              className="block text-3xl my-7 flex items-center justify-between"
              to="/about"
            >
              <span>Who We Are</span>
              <ArrowRightCircleIcon className="w-12 h-12" />
            </LocalizedLink>
            <LocalizedLink
              className="block text-3xl my-7 flex items-center justify-between"
              to="/mission"
            >
              <span>Our Mission</span>
              <ArrowRightCircleIcon className="w-12 h-12" />
            </LocalizedLink>
            <LocalizedLink
              className="block text-3xl my-7 flex items-center justify-between"
              to="/product"
            >
              <span>What We Do</span>
              <ArrowRightCircleIcon className="w-12 h-12" />
            </LocalizedLink>
            <LocalizedLink
              className="block text-3xl my-7 flex items-center justify-between"
              to="/research"
            >
              <span>Our Research</span>
              <ArrowRightCircleIcon className="w-12 h-12" />
            </LocalizedLink>
            <LocalizedLink
              className="block text-3xl my-7 flex items-center justify-between"
              to="/blog"
            >
              <span>Latest</span>
              <ArrowRightCircleIcon className="w-12 h-12" />
            </LocalizedLink>
          </div>
        </div>
        <div
          id="search"
          className={`navbar-search ${
            this.state.isSearchVisible ? '' : 'hidden'
          } bg-black md:fixed md:inset-0 md:bg-opacity-90`}
        >
          <div className="container relative h-screen py-10 md:pt-48">
            <div
              className={`navbar-search h-8 w-8 hidden md:inline-block absolute right-10 top-16 bottom-0 cursor-pointer text-white`}
              data-target="search"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleSearch()}
              onClick={() => this.toggleSearch()}
            >
              <MultiplyIcon />
            </div>
            <Search />
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
