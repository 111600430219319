import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { QuotationIcon } from '../components/Icon';
import LinkButton from '../components/LinkButton';

const ButtonMarkdownExpression = /\[(.*?)\]\((.*?)\){: \.btn}/;
const ImgMarkdownExpression = /!\[(.*?)\]\((\S*?)\s?("(.*?)?")?\)/;
const linkMarkdownExpression =
  /\[([\w\s\d@.]+)\]\(((?:\/|https?:\/\/|mailto:)[\S/?=#]+)\)/g;

function renderBlogContent(element, key, parentTag) {
  // # Handle leaf cases
  if (element.value === '\n') {
    return null;
  }
  if (element.type === 'text') {
    return element.value;
  }

  if (element.children) {
    var renderedChildren = [];
    element.children.forEach(function (child, i) {
      const childRet = renderBlogContent(child, key + '.' + i, element.tagName);
      renderedChildren.push(childRet);
    });
  }

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(element.tagName)) {
    const HeaderTag = `${element.tagName}`;
    return (
      <HeaderTag className="xl:px-24 mt-12 mb-8">{renderedChildren}</HeaderTag>
    );
  }
  if (element.tagName === 'em') {
    return <em>{renderedChildren}</em>;
  }
  if (element.tagName === 'strong') {
    return <strong>{renderedChildren}</strong>;
  }
  if (element.tagName === 'a') {
    return (
      <a
        className="text-primary hover:text-primaryHover focus:text-primaryPressed"
        href={element.properties.href}
      >
        {renderedChildren}
      </a>
    );
  }
  if (element.tagName === 'code') {
    return <code>{renderedChildren}</code>;
  }
  if (element.tagName === 'pre') {
    return <pre className="mb-6">{renderedChildren}</pre>;
  }
  if (element.tagName === 'span') {
    return <span>{renderedChildren}</span>;
  }
  if (element.tagName === 'li') {
    return <li>{renderedChildren}</li>;
  }
  if (element.tagName === 'ul') {
    if (parentTag === 'li') {
      return (
        <ul className="list-disc px-6 xl:px-8 mb-6">{renderedChildren}</ul>
      );
    } else {
      return (
        <ul className="list-disc px-12 xl:px-36 mb-6">{renderedChildren}</ul>
      );
    }
  }
  if (element.tagName === 'ol') {
    if (parentTag === 'li') {
      return (
        <ol className="list-decimal px-6 xl:px-8 mb-6">{renderedChildren}</ol>
      );
    } else {
      return (
        <ol className="list-decimal px-12 xl:px-36 mb-6">{renderedChildren}</ol>
      );
    }
  }
  if (element.tagName === 'blockquote') {
    const innerElement = renderedChildren.filter((child) => {
      return child && child.type === 'p';
    })[0];
    if (innerElement && innerElement.props) {
      return (
        <>
          <QuotationIcon className="h-14 w-14 mt-12 mb-4 text-primary" />
          <blockquote className="text-3xl mb-12">
            {innerElement.props.children[0]}
          </blockquote>
        </>
      );
    } else {
      return null;
    }
  }
  if (element.tagName === 'img') {
    return (
      <img
        className="my-12"
        src={element.properties.src}
        alt={element.properties.alt}
      />
    );
  }

  // Unwrap Images
  if (element.tagName === 'p') {
    const containsImages = renderedChildren.filter((child) => {
      return child && child.type === 'img';
    });
    if (containsImages.length >= 1) {
      return <>{renderedChildren}</>;
    } else {
      return <p className="xl:px-24 mb-6">{renderedChildren}</p>;
    }
  }

  // Fallback case
  console.warn(
    `Unable to correctly render tag ${element.tagName} in renderBlogContent()`
  );
  const TagName = `${element.tagName}`;
  return <TagName>{renderedChildren}</TagName>;
}

const BlogContent = ({ content, className = '' }) => (
  <div className={`content ${className} text-base xl:px-36 2xl:px-64`}>
    {content.children.map((child, i) => {
      return renderBlogContent(child, i);
    })}
  </div>
);

BlogContent.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
};

const MarkdownContent = ({ content }) => {
  if (!content) {
    return <></>;
  }

  let fragments = [];
  let ulBuff = [];
  let olBuff = [];

  content
    .split(/\r?\n/)
    .filter((v) => v !== '')
    .forEach((line) => {
      if (ulBuff.length && !line.match(/^\*\s(.+)/)) {
        fragments.push(
          <div
            key={uuidv4()}
            className="mb-6"
            dangerouslySetInnerHTML={{
              __html: `<ul>${ulBuff.join('\n')}</ul>`,
            }}
          ></div>
        );
        ulBuff = [];
      }
      if (olBuff.length && !line.match(/^\d+\.\s(.+)/)) {
        fragments.push(
          <div
            key={uuidv4()}
            className="mb-6"
            dangerouslySetInnerHTML={{
              __html: `<ol>${olBuff.join('\n')}</ol>`,
            }}
          ></div>
        );
        olBuff = [];
      }

      const buttonArgs = line.match(ButtonMarkdownExpression);
      const imgArgs = line.match(ImgMarkdownExpression);
      if (buttonArgs) {
        // Button
        fragments.push(
          <div key={uuidv4()} className="mb-6">
            <p key={uuidv4()}>
              <LinkButton text={buttonArgs[1]} to={buttonArgs[2]} />
            </p>
          </div>
        );
      } else if (imgArgs) {
        // Image
        fragments.push(
          <div key={uuidv4()} className="mb-6">
            <img
              key={uuidv4()}
              src={imgArgs[2]}
              alt={imgArgs[1]}
              title={imgArgs[4]}
            />
          </div>
        );
      } else {
        // Headers
        line = line.replace(/^######\s(.+)/, '<h6>$1</h6>');
        line = line.replace(/^#####\s(.+)/, '<h5>$1</h5>');
        line = line.replace(/^####\s(.+)/, '<h4>$1</h4>');
        line = line.replace(/^###\s(.+)/, '<h3>$1</h3>');
        line = line.replace(/^##\s(.+)/, '<h2>$1</h2>');
        line = line.replace(/^#\s(.+)/, '<h1>$1</h1>');

        // Bold & italic
        line = line.replaceAll(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        line = line.replaceAll(/\*(.*?)\*/g, '<i>$1</i>');

        // Links
        line = line.replaceAll(linkMarkdownExpression, "<a href='$2'>$1</a>");

        // Unordered lists
        if (line.match(/^\*\s(.+)/) && (line.split('*').length - 1) % 2 === 1) {
          line = line.replace(/^\*\s(.+)/, '<li>$1</li>');
          ulBuff.push(line);
          return;
        } else if (line.match(/^\d+\.\s(.+)/)) {
          line = line.replace(/^\d+\.\s(.+)/, '<li>$1</li>');
          olBuff.push(line);
          return;
        } else {
          line = `<p>${line}</p>`;
        }

        // Pull Quote
        if (line.match(/^>\s/)) {
          line = line.replace(/^>\s/, '');
          fragments.push(
            <blockquote className="pb-6">
              <QuotationIcon className="h-14 w-14 mb-4" />
              <div
                key={uuidv4()}
                className="mb-6"
                dangerouslySetInnerHTML={{ __html: line }}
              ></div>
            </blockquote>
          );
        } else {
          fragments.push(
            <div
              key={uuidv4()}
              className="mb-6"
              dangerouslySetInnerHTML={{ __html: line }}
            ></div>
          );
        }
      }
    });

  if (ulBuff.length) {
    fragments.push(
      <div
        key={uuidv4()}
        className="mb-6"
        dangerouslySetInnerHTML={{ __html: `<ul>${ulBuff.join('\n')}</ul>` }}
      ></div>
    );
  }
  if (olBuff.length) {
    fragments.push(
      <div
        key={uuidv4()}
        className="mb-6"
        dangerouslySetInnerHTML={{ __html: `<ol>${olBuff.join('\n')}</ol>` }}
      ></div>
    );
  }

  return <div className="markdown-content">{fragments}</div>;
};

BlogContent.propTypes = {
  content: PropTypes.string,
};

const ReactContent = ({ content, className }) => (
  <div className={className}>{content}</div>
);

ReactContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

export {
  BlogContent,
  MarkdownContent,
  ReactContent,
  ButtonMarkdownExpression,
  ImgMarkdownExpression,
};
